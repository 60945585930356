import * as dayjs from 'dayjs';

import { INewPackage, IPackage, IPackageResponse } from '@entities';
import { map, Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { fixTZ } from '../../utils/fix-tz';

@Injectable()
export class PackagesService {
  constructor(private http: HttpClient) {}

  retrieve(): Observable<IPackage[]> {
    return this.http
      .get<IPackageResponse>(`${environment.apiUrl}/api/packages.json`, {
        params: {
          showBundles: true,
          time_zone: fixTZ(dayjs.tz.guess()),
        },
      })
      .pipe(map(response => response.items));
  }

  getPackages(): Observable<INewPackage[]> {
    return this.http.get<INewPackage[]>(`${environment.apiUrlM}/api/v2/admin/packages`);
  }

  getPackage(id: number): Observable<INewPackage> {
    return this.http.get<INewPackage>(`${environment.apiUrlM}/api/v2/admin/packages/${id}`);
  }

  createPackage(data: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrlM}/api/v2/admin/packages`, data);
  }
}
